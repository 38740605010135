var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('info-block',{attrs:{"infotext":_vm.infotext,"title":_vm.infoTitle}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.allheaders,"items":_vm.playerList,"fixed-header":"","height":"700","items-per-page":50,"dense":"","mobile-breakpoint":"1","single-select":""},scopedSlots:_vm._u([{key:"header.score",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.globalScore",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.stars",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.tobiiScore",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.raffleTickets",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.goldMedals",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.silverMedals",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},{key:"header.bronzeMedals",fn:function(ref){
var header = ref.header;
return [_c('tooltip-header',{attrs:{"header":header}})]}},_vm._l((_vm.dayheaders),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:h.value,domProps:{"innerHTML":_vm._s(header.text)}})]}}}),{key:"item.identity",fn:function(ref){
var item = ref.item;
return [_c('position-col',{attrs:{"item":item}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('name-col',{attrs:{"item":item}})]}},{key:"item.stars",fn:function(ref){
var item = ref.item;
return [_c('star-col',{attrs:{"item":item}})]}},_vm._l((_vm.dayheaders),function(h){return {key:("item." + (h.value)),fn:function(ref){
var item = ref.item;
return [_c('value-col',{key:h.value,attrs:{"item":item,"header":h.value,"getValue":_vm.getValue}})]}}}),{key:"no-data",fn:function(){return [_vm._v(" No data available ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }